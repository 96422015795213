import React from "react";
import { Layout } from "antd";

import "./App.css";

const { Header, Content } = Layout;

function App() {
  return (
    <div className="App">
      <Header
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          width: "100%",
          backgroundColor: "#FFFFFF",
          borderBottom: "1px solid #EBEBEB",
          height: "70px",
          padding: "0 27px 0 27px",
        }}
      >
        <span
          style={{
            fontFamily: "PoetsenOne",
            fontSize: "40px",
            lineHeight: "70px",
            float: "left",
          }}
        >
          VEIL
        </span>
        <a
          href="https://forms.gle/CTVKL6jo4sEK3Jq47"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            float: "right",
            width: "115px",
            height: "36px",
            background: "#AD002B",
            border: "1px solid #000000",
            borderRadius: "50px",
            color: "white",
            fontSize: "14px",
            fontWeight: "bold",
            lineHeight: "36px",
            marginTop: "18px",
            cursor: "pointer",
            padding: 0,
          }}
        >
          Join waitlist
        </a>
      </Header>
      <Content
        className="site-layout"
        style={{
          padding: "0 27px",
          backgroundColor: "#FFF",
          fontFamily: "Poppins, sans-serif",
        }}
      >
        <div id="main-image" />
        <div
          style={{
            margin: "20px 0 0 0",
            width: "100%",
            fontFamily: "PoetsenOne",
            fontSize: "58px",
            lineHeight: "66px",
          }}
        >
          VEIL
        </div>
        <div
          style={{
            margin: "4px 0 0 0",
            width: "100%",
            fontFamily: "PoetsenOne",
            fontSize: "22px",
            lineHeight: "26px",
          }}
        >
          Match with exciting locals & travelers for unforgettable nights in
          Chiang Mai
        </div>
        <a
          id="first-joinBtn"
          href="https://forms.gle/CTVKL6jo4sEK3Jq47"
          target="_blank"
          rel="noopener noreferrer"
        >
          <p>Join waitlist</p>
          <p>Available in April</p>
        </a>
        <div id="read-more">Read More ⬇️</div>
        <div id="welcome">
          <div className="section-title">Welcome to Veil</div>
          <div className="section-content">
            Get ready to discover <br /> new experiences and
            <br />
            make unforgettable memories.
          </div>
          <div>Let's make every night special!</div>
        </div>
        <div id="daily-match">
          <div />
          <div className="section-title">Daily Matches</div>
          <div className="section-content">
            Get 3 curated profiles for free
            <br />
            every day and discover new people
          </div>
        </div>
        <div id="simple-easy">
          <div />
          <div className="section-title">Simple & Easy</div>
          <div className="section-content">
            Just add 2 photos, a 40-word bio, <br />
            and you're all set
          </div>
        </div>
        <div id="straight-forward">
          <div />
          <div className="section-title">Straight Forward</div>
          <div className="section-content">
            No more in-app messaging, <br />
            connect directly via Instagram <br />
            for a quick start
          </div>
        </div>
        <div id="lgbt-friendly">
          <div />
          <div className="section-title">LGBT+ Friendly</div>
          <div className="section-content">No judgment, Pure satisfaction</div>
        </div>
      </Content>
      <div id="footer" style={{ backgroundColor: "#FFF" }}>
        <div id="available">
          <div className="section-title">Available in April</div>
          <div className="section-content">Keep up with our latest news</div>
          <a
            href="https://www.instagram.com/veil.app/"
            target="_blank"
            rel="noopener noreferrer"
          >
            CHECK OUR INSTAGRAM
          </a>
        </div>
        <div>© 2023 veilapp.io, Inc. All rights reserved.</div>
      </div>
    </div>
  );
}

export default App;
